.text-paragraph {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.text-paragraph a {
    text-decoration: underline;
    font-weight: 700;
}

.h1 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
}

.h2 {
    font-size: 0.9375rem;
    line-height: 1.25rem;
    letter-spacing: 0.0187rem;
    font-weight: 800;
}

.h3 {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.3px;
    font-weight: 700;
}

.p1 {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
}

.p2 {
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
}

.p3 {
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
}

.p4 {
    font-size: 15px;
    line-height: 22px;
    font-weight: 300;
}

.text-button {
    font-size: 13px;
    line-height: 18px;
    font-weight: 800;
    letter-spacing: 0.3px;
}

.text-small {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}

.text-small-bold {
    font-size: 14px;
    line-height: 20px;
    font-weight: 800;
}

@media (max-width: 1536px) {
    .h1 {
        font-size: 16px;
        line-height: 20px;
    }

    .h2 {
        font-size: 0.8125rem;
        line-height: 1.125rem;
    }

    .h3 {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0px;
    }

    .p1, .p2, .p3 {
        font-size: 14px;
        line-height: 20px;
    }

    .p4 {
        font-size: 13px;
        line-height: 20px;
    }

    .text-button {
        font-size: 11px;
        line-height: 15px;
    }

    .text-small, .text-small-bold {
        font-size: 12px;
        line-height: 18px;
    }

    text-status {
        color: var(--color-4);
        padding: 4px 8px;
        background-color: var(--color-4_7);
    }
}