@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  animation: loader-rotate 1s linear infinite;
}

.loaderSpinnerInner {
  top: -4px;
  left: -4px;
  border-top: 4px solid var(--color-4);
  border-right: 4px solid transparent !important;
  border-left: 4px solid transparent !important;
  border-bottom: 4px solid transparent !important;
  border-radius: 50%;
}
