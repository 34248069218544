.flashing-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin: 0 5px;
  animation: flashingDot 1s infinite linear alternate;
}

@keyframes flashingDot {
  0% {
    opacity: 10%;
  }
  30% {
    opacity: 40%;
  }
  70% {
    opacity: 70%;
  }
  100% {
    opacity: 100%;
  }
}