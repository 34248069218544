body,
html {
  min-width: 100%;
  position: relative;
  font-size: 16px;
}

.flex-basis-100 {
  flex-basis: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input:checked + svg {
  display: block;
}

.mobile-cart-row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.25rem;
}

.default-table .default-row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.25rem 1rem;
}

.react-table-row th {
  padding: 0.75rem 8px;
}

.react-table-row td {
  padding: 1rem 8px;
}

@media (min-width: 1536px) {
  .react-table-row th {
    padding: 0.75rem 12px;
  }

  .react-table-row td {
    padding: 1rem 12px;
  }
}

.react-tel-input input.form-control {
  width: 100%;
}

.react-tel-input :disabled {
  cursor: default;
}

.react-tel-input .country {
  color: black;
}

.modal .react-tel-input .country-list {
  position: fixed;
}

.hide-number::-webkit-outer-spin-button,
.hide-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hide-number {
  -moz-appearance: textfield;
}

.Toastify__toast-body {
  word-break: break-word;
}

.singleline-truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.multiline-truncate-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-navigation::-webkit-scrollbar {
  display: none;
}

.header-navigation {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.pageContainer {
  padding: 24px 64px;
}

.revertPageContainer {
  margin: -24px -64px;
}

@media (max-width: 1280px) {
  .pageContainer {
    padding: 24px 56px;
  }
  .revertPageContainer {
    margin: -24px -56px;
  }
}

@media (max-width: 768px) {
  .pageContainer {
    padding: 20px 16px;
  }

  .revertPageContainer {
    margin: -20px -16px;
  }
}

.bg-button {
  background-color: var(--color-4);
}

.bg-button:hover {
  background-color: var(--color-5);
}

.text-gradient {
  background-image: linear-gradient(
    180deg,
    var(--color-4_40) 0%,
    var(--color-4_5)
  );
}

.sidebarLink-active-highlight {
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
}

.custom-scrollbar {
  scrollbar-color: var(--color-4--20) var(--color-3--2);
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: var(--color-4--20);
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: var(--color-3--2);
}

.bg-toggle {
  background: linear-gradient(to right, var(--color-4) 50%, var(--color-4--40) 50%);
}

.modalContent {
  box-shadow: 0px 2px 12px var(--color-3--20_40);
}

.inputField:focus {
  border: 1px solid var(--color-5) !important;
}

.focus\:boxShadowNoneImportant:focus {
  box-shadow: none !important;
}

.focus\:bg-9-10:focus {
  background-color: var(--color-9-10) !important;
}
.hover\:bg-9--5:hover {
  background-color: var(--color-9--5) !important;
}

.hover\:border-10:hover {
  border: 1px solid var(--color-10);
}

.searchInput::placeholder {
  color: var(--color-8_60);
}
