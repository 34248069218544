@supports (overflow: overlay) {
  .overlay-kyc-tiers {
    overflow-x: overlay;
  }

  .firefox-scrollbar-hack {
    display: none;
  }
}

@supports not (overflow: overlay) {
  .overlay-kyc-tiers {
    overflow-x: scroll;
  }

  .firefox-scrollbar-hack {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 230px;
    height: 56px;
  }
}

.custom-scrollbar {
  scrollbar-width: none;
}

.custom-scrollbar::-webkit-scrollbar {
  display: none;
}

@media (min-width: 640px) {
  .custom-scrollbar {
    scrollbar-width: thin;
  }

  .custom-scrollbar::-webkit-scrollbar {
    display: block;
    height: 6px;
  }
}
