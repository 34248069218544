.tooltip-arrow,
.tooltip-arrow::before {
  width: 10px;
  height: 10px;
  position: absolute;
}
.tooltip-arrow::before {
  content: "";
  transform: rotate(45deg);
  background-color: var(--color-4--20);
}

.tooltip[data-popper-placement='top'] .tooltip-arrow {
  bottom: -5px;
}

.tooltip[data-popper-placement='bottom'] .tooltip-arrow {
  top: -5px;
}

.tooltip[data-popper-reference-hidden='true'] {
  visibility: hidden;
}

.tooltip {
  z-index: 999;
}
